<template>
  <div>
    <div class="login-all">
      <div class="login-bg"></div>
      <div class="login-body">
        <div class="body-left"></div>
        <div class="body-right">
          <!-- 账号密码登录 -->
          <div class="userPwd">
            <div class="UP-top">
              <h1>登录 通行证</h1>
            </div>
            <div class="UP-center">
               <div :class="['userPhone',{'moveHover': RigPhone == false}, {'borderchange': RigPhone}]">
                  <el-input v-model="userPhone" placeholder="请输入手机号" @blur="checkPhone()"></el-input>
                  <div v-if="RigPhone" class="errortxt">请输入正确手机号</div>
                </div>
                
                <div :class="['userPasswd',{'moveHover': RigPwd == false}, {'borderchange': RigPwd}]">
                  <el-input show-password v-model="userPasswd" placeholder="请输入密码" @blur="checkPwd()"></el-input>
                  <!-- <div class="showHide">
                    <img :src="showHide" alt="">
                  </div> -->
                  <div v-if="RigPwd" class="errortxt">请输入正确密码</div>
                </div>
                <div class="forgetPwd" @click="this.$router.push('/forgot')">忘记密码</div>
            </div>
            <div class="UP-bottom">
              <div class="login-btn" @click="loginTo()">登录</div>
              <p>或</p>
              <div class="wxCode" @click="this.$router.push('/wxcode')">
                <div>
                  <img src="../../assets/wxicon.png" alt="">
                </div>
                <span>微信扫码登录</span>
              </div>
              <div class="toCode">
                <div @click="this.$router.push('/login/code')">验证码登录</div>
                <!-- <div @click="this.$router.push('/register')" style="margin-left: 15px;">注册</div> -->
              </div>
            </div>
            
          </div>
          <!-- 扫码登录 -->
         <!--  <div v-if="type == 3" class="scanningCode"></div> -->
          <!-- 找回密码 -->
          <!-- <div v-if="type == 4" class="retrievePwd"></div> -->
        </div>
      </div>
    </div>
 </div>
</template>

<script>
import "./login.css"
import { userpwdLogin } from "../../api/login";
import { setCookie } from "../../api/method";

export default {
  name: '',
  data () {
    return {
      userPhone: '',//手机号
      userPasswd: '',//密码
      // showHide: require("../../assets/hide.png"),
      RigPwd: false, //密码校验
      RigPhone: false, //手机校验
     
    }
  },
  props: {},

  mounted () {},
  methods: {

    checkPhone() {
      const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      // console.log(!reg.test(this.userPhone))
      if(this.userPhone == '' || !reg.test(this.userPhone)) {
        this.RigPhone = true
        return false
        // console.log(this.RigPhone)
      } else {
        this.RigPhone = false
      }
    },
    checkPwd() {
      if(this.userPasswd == '') {
        this.RigPwd = true
        return false
      } else {
        // console.log(this.userPasswd.length)
        
        if(this.userPasswd.length < 6) {
          this.$message({
            message: '密码不能低于6位数',
            type: 'error'
          })
          this.RigPwd = true
          return false
        } else {
          this.RigPwd = false
        }
         
      }
    },
    loginTo() {
      this.checkPhone()
      this.checkPwd()
      console.log(this.RigPhone,this.RigPwd)
      
      if(this.RigPhone == false && this.RigPwd == false) {
        // console.log('sss')
        const user = {
          mobile: this.userPhone,
          password: this.userPasswd
        }
        userpwdLogin(user).then(res => {
          console.log(res)
          if(res.data.code == 4000004) {
            this.$message({
              message: '手机号或密码错误',
              type: 'error'
            })
          }
          if(res.data.code == 4000003) {
            this.$message({
              message: '用户不存在',
              type: 'error'
            })
          }
          if(res.data.code == 0) {
            setCookie('accessToken',res.data.data.accessToken,1)
            // setCookie('id', res.data.data.id,1)
            // setCookie('phone', res.data.data.phone,1)
            setCookie('refresh_token', res.data.data.refreshToken,1)
            this.$router.push('/passportInfo')
          }
        })
      }
      
      
    }
  }
}
</script>

<style scoped lang='less'>

</style>
