import axios from 'axios'
import qs from "qs";

// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

// 创建一个axios实例
const request = axios.create({
  baseURL: 'https://passport.doit.com.cn'
  // baseURL: 'http://192.168.2.205:8080'
  // 定制后端返回的原始数据（data), 即未经过JSON格式处理的原始数据
  /* transformResponse: [function (data) {
    console.log(data)
    // axios默认在内部使用JSON.parse来转换处理原始数据
    return JSON.parse(data)
  }] */
})

request.interceptors.request.use(config => {
  // console.log(config)
  if(config.headers["Content-Type"]){
    if(config.method === 'post') {
      if(config.url === '/api/user/replenish' || config.url === '/api/user/modify' || config.url === '/api/user/register/replenish') {
        config.headers["Content-Type"] = "application/json"; // 默认类型
      }else {
        if(config.url === '/api/file/upload') {
          config.headers["Content-Type"] = "application/multipart/form-data";
        }else {
          config.headers["Content-Type"] = "application/x-www-form-urlencoded"; // post 请求
          config.data = qs.stringify(config.data); // 序列化,比如表单数据
          // console.log(config.data)
        }
        
      }
      

    } else {
      // console.log('rrr')
      config.headers["Content-Type"] = "application/json"; // 默认类型
    }
    
  }
  return config;
  
})
export default request
