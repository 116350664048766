import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import { getCookie, setCookie } from '../api/method'
import { getUserInformation, ifcode } from '../api/passportInfo'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {//注册
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "about" */ '@/views/login/Register.vue')
  },
  {//忘记密码
    path: '/forget',
    name: 'Forget',
    component: () => import('@/views/forget/Forget.vue')
  },
  {//重置密码
    path: '/resetPassword',
    name: 'Forget2',
    component: () => import('@/views/forget/Forget2.vue')
  },
  {
    path: '/passportInfo',
    name: 'Info',
    component: () => import('@/views/info/Info.vue')
  },
  {
    path: '/vip',
    name: 'Vip',
    component: () => import('@/views/vip/Vip.vue')
  },
  {//验证码登录
    path: '/login/code',
    name: 'LoginCode',
    component: () => import('@/views/login/LoginCode.vue')
  },
  {//忘记密码
    path: '/forgot',
    name: 'Forgot',
    component: () => import('@/views/login/Forgot.vue')
  },
  {//扫码登录
    path: '/wxcode',
    name: 'WxCode',
    component: () => import('@/views/login/WxCode.vue')
  },
  {//扫码支付成功
    path: '/paySuccess',
    name: 'PaySuccess',
    component: () => import('@/views/success/PaySuccess.vue')
  },
  {//注册信息完善
    path: '/Information',
    name: 'Information',
    component: () => import('@/views/login/Information.vue')
  },
]

const router = createRouter({
  routes,
  history: createWebHashHistory()
})
router.beforeEach((to, from, next) => {
  // console.log(to)
  if(to.path === '/passportInfo' || to.path === '/vip') {
    if(to.path === '/passportInfo' && to.query.ifcode == 1 || to.path === '/passportInfo' && to.query.ifBindwx == 1) {
      console.log(getCookie('code'))
      
      const code = {
        code: getCookie('code')
      }
      ifcode(code).then(res => {
        // console.log(res)
        if(res.data.code == 0) {
          setCookie('accessToken',res.data.data.accessToken,1)
          // setCookie('id', res.data.data.id,1)
          // setCookie('phone', res.data.data.phone,1)
          setCookie('refresh_token', res.data.data.refreshToken,1)
          next()
        }else {
          next('/')
        }
        
      })
    }
    // if(to.path === '/passportInfo' && to.query.ifBindwx == 1) {
    //   // this.$message({
    //   //   type: 'success',
    //   //   message: '绑定成功!'
    //   // });
    //   next()
    // }
    // if(to.path === '/passportInfo' && to.query.ifBindwx == 2) {
    //   this.$message({
    //     type: 'error',
    //     message: '绑定失败!'
    //   });
    //   next('/passportInfo')
    // }
    else {
      if(getCookie('accessToken')) {
        getUserInformation().then(res => {
          if(res.data.code == 0) {
            next()
          }else {
            next('/')
          } 
        })  
      }else {
        next('/')
      }         
    }
    
     
  } else {
    if(getCookie('accessToken')) {
      getUserInformation().then(res => {
        if(res.data.code == 0) {
          next('/passportInfo')
        }else {
          next()
        } 
      })  
    }else {
      next()
    }         
  }
  // if(to.query.code) {
  //   next('/passportInfo')
  //   console.log(to.query.code)
    
  // } else {
  //   next()
  // }
})
export default router
