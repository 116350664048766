/**
 * 登录请求
 */
export const getCaptcha = data => {
  return request({
    method: 'GET',
    url: '/api/capt',
    /* headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }, */
    dataType:'json',
    data 
  })
}
import request from '@/utils/request.js'
//手机密码登录
export const userpwdLogin = data => {
  return request({
    method: 'POST',
    url: '/api/user/login/password',
    headers:{
      "Content-Type":true
    },
    dataType:'json',
    data
  })
}
//生成微信二维码 
export const getwxCode = () => {
  return request({
    method: 'GET',
    url: '/api/user/vx/generate/code',
    dataType:'json',
    // headers: {
    //     'Content-Type': 'application/x-www-form-urlencoded'
    // },
  })
}
//检测二维码是否过期
export const getwxCode_pass = data => {
  return request({
    method: 'GET',
    url: `/api/vx/thirdLogin/check?code=${data}`,
    // headers: {
    //   'Content-Type': 'application/x-www-form-urlencoded'
    // },
    dataType:'json',
    data

  })
}
//手机验证码登录
export const userCodeLogin = data => {
  return request({
    method: 'POST',
    url: '/api/user/login/mobile',
    data,
    dataType:'json',
    headers:{
      "Content-Type":true
    },
  })
}
//忘记密码修改
export const forgetUpdate = data => {
  return request({
    method: 'POST',
    url: '/api/user/forget/password',
    data,
    dataType:'json',
    headers:{
      "Content-Type":true
    },
  })
}
