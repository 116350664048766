<template>
    <Login />
</template>

<script>
// @ is an alias to /src
// import swiper from '@/views/swiper/Swiper.vue'
import Login from '@/views/login/Login.vue'

export default {
  name: 'Home',
  components: {
    Login
  }
}
</script>
<style>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}


</style>
