/**
 * 方法
 */
//获取cookie、
export const getCookie = (name) => {
  var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
  if (arr = document.cookie.match(reg))
   return (arr[2]);
  else
   return null;
 }
 
 //设置cookie,增加到vue实例方便全局调用
 export const setCookie = (c_name, value, expiredays,domain) => {
  var exdate = new Date();
  exdate.setDate(exdate.getDate() + expiredays);
  document.cookie = c_name + "=" + escape(value) +((domain == null) ? "" : (";domain=" + domain))+((expiredays == null) ? "" : ";expires=" + exdate.toGMTString());
 };
 
 //删除cookie
 export const delCookie = (name,domain) => {
  var exp = new Date();
  exp.setTime(exp.getTime() - 1);
  var cval = getCookie(name);
  if (cval != null)
   document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString()+((domain == null) ? "" : (";domain=" + domain));
};
export const getRequestParams = (params) => {
  let url = location.href;
   url = url.replace("?", "?&").split("&");
   let re = "";
   for (let i = 1; i < url.length; i++) {
     if (url[i].indexOf(params + "=") == 0) {
       re = url[i].replace(params + "=", "");
        if (re.indexOf("#") !== -1) {
         re = re.split("#")[0];
     }
     }
   }
   return re;
}