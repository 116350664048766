/**
 * 首页接口
 */
import request from '@/utils/request.js'
import { getCookie } from './method'
//查询用户信息
export const getUserInformation = data => {
  return request({
    method: 'GET',
    url: `/api/user/get`,
    headers: {
      'Authorization': getCookie('accessToken'),
      'refresh_token': getCookie('refresh_token'),
      "Content-Type":true
    },
    dataType:'json',
    data

  })
}
//退出登录
export const outLoginTo = data => {
  return request({
    method: 'POST',
    url: `/api/user/logout`,
    headers: {
      'Authorization': getCookie('accessToken'),
      'refresh-token': getCookie('refresh_token'),
      "Content-Type":true
    },
    dataType:'json',
    data

  })
}
//注销用户
export const cancellation = data => {
  return request({
    method: 'POST',
    url: '/api/user/remove',
    data,
    dataType:'json',
    headers:{
      'Authorization': getCookie('accessToken'),
      'refresh_token': getCookie('refresh_token'),
      "Content-Type":true
    },
  })
}
//修改信息
export const updateInfomation = data => {
  return request({
    method: 'POST',
    url: `/api/user/modify`,
    headers: {
      'Authorization': getCookie('accessToken'),
      'refresh_token': getCookie('refresh_token'),
      "Content-Type":true
    },
    dataType:'json',
    data

  })
}
//修改邮箱
export const emailCode = data => {
  return request({
    method: 'POST',
    url: `/api/send/emailCode`,
    headers: {
      'Authorization': getCookie('accessToken'),
      'refresh_token': getCookie('refresh_token'),
      "Content-Type":true
    },
    dataType:'json',
    data

  })
}
//解绑微信
export const unBind = data => {
  return request({
    method: 'POST',
    url: `/api/user/vx/unbind`,
    headers: {
      'Authorization': getCookie('accessToken'),
      'refresh_token': getCookie('refresh_token'),
      "Content-Type":true
    },
    dataType:'json',
    data

  })
}
//用户收藏
export const collect = data => {
  return request({
    method: 'GET',
    url: `https://edu.doit.com.cn/user/collect?uid=${getCookie('id')}`,
    // headers: {
    //   "Content-Type":true
    // },
    dataType:'json',
    data

  })
}
//购物车
export const shoppingCart = data => {
  return request({
    method: 'GET',
    url: `https://edu.doit.com.cn/pay/user_pay?userid=${getCookie('id')}`,
    // headers: {
    //   "Content-Type":true
    // },
    dataType:'json',
    data

  })
}
//校验邮箱验证码
export const checkemailCode = data => {
  return request({
    method: 'POST',
    url: `/api/email/check`,
    headers: {
      'Authorization': getCookie('accessToken'),
      'refresh_token': getCookie('refresh_token'),
      "Content-Type":true
    },
    dataType:'json',
    data

  })
}
//校验手机验证码
export const checkphoneCode = data => {
  return request({
    method: 'POST',
    url: `/api/checkCode`,
    headers: {
      // 'Authorization': getCookie('accessToken'),
      // 'refresh_token': getCookie('refresh_token'),
      "Content-Type":true
    },
    dataType:'json',
    data

  })
}

export const ifcode = data => {
  return request({
    method: 'POST',
    url: `/api/user/code/login`,
    headers: {
      'Content-Type': true
    },
    dataType:'json',
    data

  })
}
//头像上传
export const uploadHeadImg = data => {
  return request({
    method: 'POST',
    url: '/api/file/upload',
    data,
    dataType:'json',
    headers:{
      'Authorization': getCookie('accessToken'),
      'refresh_token': getCookie('refresh_token'),
      "Content-Type":true
    },
  })
}
//修改头像
export const upImg = data => {
  return request({
    method: 'POST',
    url: `/api/user/modify`,
    headers: {
      'Authorization': getCookie('accessToken'),
      'refresh_token': getCookie('refresh_token'),
      "Content-Type":true
    },
    dataType:'json',
    data

  })
}
//
export const ifbind = data => {
  return request({
    method: 'POST',
    url: `/api/user/vx/isBind`,
    headers: {
      'Authorization': getCookie('accessToken'),
      'refresh_token': getCookie('refresh_token'),
      "Content-Type":true
    },
    dataType:'json',
    // data
  })
}